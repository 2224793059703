/**
 * Login Page
 */

// Core imports
import React from 'react';
import PropTypes from 'prop-types';

// Style, SEO and settings
import Helmet from 'react-helmet';
import { Col, css, Grid, Row, up, styled, withTheme } from '@smooth-ui/core-sc';

// Additional Components/Containers
import LoadAsync from '_platform/src/utils/LoadAsync';
import PublicComponent from '_platform/src/utils/PublicComponent';
import getReturnUrlPath from '_platform/src/utils/getReturnUrlPath';

import { withSettings } from 'containers/WebApp/SettingsContext';
import bgImage1x from './images/Login-BG@1x.jpg';
import bgImage2x from './images/Login-BG@2x.jpg';
import logo from '../../theme/Logo-Login@2x.png';

const LoginProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "loginProvider" */ '_platform/src/containers/LoginProvider/LoginProvider'
  )
);
const LoginForm = LoadAsync(() =>
  import(
    /* webpackChunkName: "loginForm" */ '_platform/src/components/LoginForm/LoginForm'
  )
);

const PageContainer = styled.div`
  align-items: center;
  background-color: #131d28;
  background-image: url(${bgImage1x});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  @media only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 144dpi),
    only screen and (min-resolution: 1.5dppx) {
    background-color: #131d28;
    background-image: url(${bgImage2x});
  }
`;

const LoginLogo = styled.div`
  position: relative;
  background: linear-gradient(180deg, #fff 50%, #044da1 50%);
  height: 300px;

  img {
    height: auto;
    width: 96%;
    max-width: 405px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${up(
    'md',
    css`
      height: 400px;
    `
  )};

  ${up(
    'lg',
    css`
      height: 448px;
    `
  )};
`;

const IntroContainer = styled.div`
  box-sizing: border-box;
  color: #fff;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  padding: 2rem;
  h2 {
    margin-top: 0;
    font-size: 30px;
  }
  p {
    color: #fff;
  }
  a {
    color: #fff;
    &:visited {
      color: #fff;
    }
  }
`;

const LoginContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  padding: 2rem;

  .reset-password {
    a {
      font-size: 14px;
      font-weight: normal;
    }
  }

  button {
    text-transform: uppercase;
    font-weight: bold;
  }

  ${up(
    'lg',
    css`
      padding-left: 2rem;
      min-width: 300px;
    `
  )};
`;

const LoginPage = ({ settings }) => (
  <PublicComponent
    redirectOnError={
      // Redirect to returnUrl, if it's not the same as loginPagePath
      (getReturnUrlPath !== settings.settingsApp.loginPagePath &&
        getReturnUrlPath) ||
      '/'
    }
  >
    <Helmet>
      <title>Login</title>
      {/*<meta name="description" content="Page description" />*/}
    </Helmet>

    <PageContainer>
      <Grid className="page-container">
        <IntroContainer>
          <Grid>
            <Row justifyContent="center">
              <Col xs={12} sm={10} lg={6}>
                <LoginLogo className="text--center">
                  <img
                    src={logo}
                    alt={
                      settings.settingsApp && settings.settingsApp.siteName
                        ? settings.settingsApp.siteName
                        : 'ProgramName'
                    }
                  />
                </LoginLogo>
              </Col>
              <Col xs={12} sm={10} lg={6}>
                <LoginContainer>
                  <h2 className="text--uppercase">Login Below</h2>

                  <LoginProvider disableMeta>
                    <LoginForm formLabels={{ labelUserName: 'Username' }} />
                  </LoginProvider>
                </LoginContainer>
              </Col>
            </Row>
          </Grid>
        </IntroContainer>
      </Grid>
    </PageContainer>
  </PublicComponent>
);

LoginPage.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default withTheme(withSettings(LoginPage));
